.marquee-container {
  width: 100%; /* Ajuste conforme necessário */
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}

.marquee-text {
  display: inline-block;
  white-space: nowrap;
  /* Retirar o overflow e ellipsis daqui para a animação funcionar */
}

.marquee-animate {
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(0);
  }
  90% {
    transform: translateX(calc(-100% + 100%));
  }
  100% {
    transform: translateX(calc(-100% + 100%));
  }
}
