.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Ajuste a opacidade conforme necessário */
  z-index: 998; /* Abaixo do carregamento */
  display: flex;
  justify-content: center;
  align-items: center;
}
.column {
	 width: 100vw;
	 height: 100vh;
	 display: flex;
   z-index: 999;
   justify-content: center;
	 align-items: center;
}
 .container {
	 width: 50px;
	 height: 50px;
	 position: relative;
}
 .container.animation-1 {
	 transform: rotate(45deg);
}
 .container.animation-2, .container.animation-4 {
	 transform: rotate(10deg);
}
 .container.animation-2 .shape, .container.animation-4 .shape {
	 border-radius: 5px;
}
 .container.animation-2, .container.animation-3, .container.animation-4 {
	 animation: rotation 1s infinite;
}
 .container.animation-3 .shape1 {
	 border-top-left-radius: 10px;
}
 .container.animation-3 .shape2 {
	 border-top-right-radius: 10px;
}
 .container.animation-3 .shape3 {
	 border-bottom-left-radius: 10px;
}
 .container.animation-3 .shape4 {
	 border-bottom-right-radius: 10px;
}
 .container.animation-4, .container.animation-5 {
	 transform: rotate(45deg);
}
 .container.animation-5 .shape {
	 width: 15px;
	 height: 15px;
}
 .container.animation-6 {
	 animation: rotation 1s infinite;
}
 .container.animation-6 .shape {
	 width: 12px;
	 height: 12px;
	 border-radius: 2px;
}
 .container .shape {
	 position: absolute;
	 width: 20px;
	 height: 20px;
	 border-radius: 100% !important;
}
 .container .shape.shape1 {
	 left: 0;
	 background-color: #5c6bc0;
}
 .container .shape.shape2 {
	 right: 0;
	 background-color: #8bc34a;
}
 .container .shape.shape3 {
  bottom: 0;
  left: 0;
	 background-color: #ffb74d;
}
 .container .shape.shape4 {
	 bottom: 0;
	 right: 0;
	 background-color: #f44336;
}
 @keyframes rotation {
	 from {
		 transform: rotate(0deg);
	}
	 to {
		 transform: rotate(360deg);
	}
}
 .animation-1 .shape1 {
	 animation: animation1shape1 0.5s ease 0s infinite alternate;
}
 @keyframes animation1shape1 {
	 from {
		 transform: translate(0, 0);
	}
	 to {
		 transform: translate(16px, 16px);
	}
}
 .animation-1 .shape2 {
	 animation: animation1shape2 0.5s ease 0s infinite alternate;
}
 @keyframes animation1shape2 {
	 from {
		 transform: translate(0, 0);
	}
	 to {
		 transform: translate(-16px, 16px);
	}
}
 .animation-1 .shape3 {
	 animation: animation1shape3 0.5s ease 0s infinite alternate;
}
 @keyframes animation1shape3 {
	 from {
		 transform: translate(0, 0);
	}
	 to {
		 transform: translate(16px, -16px);
	}
}
 .animation-1 .shape4 {
	 animation: animation1shape4 0.5s ease 0s infinite alternate;
}
 @keyframes animation1shape4 {
	 from {
		 transform: translate(0, 0);
	}
	 to {
		 transform: translate(-16px, -16px);
	}
}
 .animation-2 .shape1 {
	 animation: animation2shape1 0.5s ease 0s infinite alternate;
}
 @keyframes animation2shape1 {
	 from {
		 transform: translate(0, 0);
	}
	 to {
		 transform: translate(20px, 20px);
	}
}
 .animation-2 .shape2 {
	 animation: animation2shape2 0.5s ease 0s infinite alternate;
}
 @keyframes animation2shape2 {
	 from {
		 transform: translate(0, 0);
	}
	 to {
		 transform: translate(-20px, 20px);
	}
}
 .animation-2 .shape3 {
	 animation: animation2shape3 0.5s ease 0s infinite alternate;
}
 @keyframes animation2shape3 {
	 from {
		 transform: translate(0, 0);
	}
	 to {
		 transform: translate(20px, -20px);
	}
}
 .animation-2 .shape4 {
	 animation: animation2shape4 0.5s ease 0s infinite alternate;
}
 @keyframes animation2shape4 {
	 from {
		 transform: translate(0, 0);
	}
	 to {
		 transform: translate(-20px, -20px);
	}
}
 .animation-3 .shape1 {
	 animation: animation3shape1 0.5s ease 0s infinite alternate;
}
 @keyframes animation3shape1 {
	 from {
		 transform: translate(0, 0);
	}
	 to {
		 transform: translate(5px, 5px);
	}
}
 .animation-3 .shape2 {
	 animation: animation3shape2 0.5s ease 0s infinite alternate;
}
 @keyframes animation3shape2 {
	 from {
		 transform: translate(0, 0);
	}
	 to {
		 transform: translate(-5px, 5px);
	}
}
 .animation-3 .shape3 {
	 animation: animation3shape3 0.5s ease 0s infinite alternate;
}
 @keyframes animation3shape3 {
	 from {
		 transform: translate(0, 0);
	}
	 to {
		 transform: translate(5px, -5px);
	}
}
 .animation-3 .shape4 {
	 animation: animation3shape4 0.5s ease 0s infinite alternate;
}
 @keyframes animation3shape4 {
	 from {
		 transform: translate(0, 0);
	}
	 to {
		 transform: translate(-5px, -5px);
	}
}
 .animation-4 .shape1 {
	 animation: animation4shape1 0.3s ease 0s infinite alternate;
}
 @keyframes animation4shape1 {
	 from {
		 transform: translate(0, 0);
	}
	 to {
		 transform: translate(8px, 8px);
	}
}
 .animation-4 .shape2 {
	 animation: animation4shape2 0.3s ease 0.3s infinite alternate;
}
 @keyframes animation4shape2 {
	 from {
		 transform: translate(0, 0);
	}
	 to {
		 transform: translate(-5px, 5px);
	}
}
 .animation-4 .shape3 {
	 animation: animation4shape3 0.3s ease 0.3s infinite alternate;
}
 @keyframes animation4shape3 {
	 from {
		 transform: translate(0, 0);
	}
	 to {
		 transform: translate(5px, -5px);
	}
}
 .animation-4 .shape4 {
	 animation: animation4shape4 0.3s ease 0s infinite alternate;
}
 @keyframes animation4shape4 {
	 from {
		 transform: translate(0, 0);
	}
	 to {
		 transform: translate(-5px, -5px);
	}
}
 .animation-5 .shape1 {
	 animation: animation5shape1 2s ease 0s infinite reverse;
}
 @keyframes animation5shape1 {
	 0% {
		 transform: translate(0, 0);
	}
	 25% {
		 transform: translate(0, 15px);
	}
	 50% {
		 transform: translate(15px, 15px);
	}
	 75% {
		 transform: translate(15px, 0);
	}
}
 .animation-5 .shape2 {
	 animation: animation5shape2 2s ease 0s infinite reverse;
}
 @keyframes animation5shape2 {
	 0% {
		 transform: translate(0, 0);
	}
	 25% {
		 transform: translate(-15px, 0);
	}
	 50% {
		 transform: translate(-15px, 15px);
	}
	 75% {
		 transform: translate(0, 15px);
	}
}
 .animation-5 .shape3 {
	 animation: animation5shape3 2s ease 0s infinite reverse;
}
 @keyframes animation5shape3 {
	 0% {
		 transform: translate(0, 0);
	}
	 25% {
		 transform: translate(15px, 0);
	}
	 50% {
		 transform: translate(15px, -15px);
	}
	 75% {
		 transform: translate(0, -15px);
	}
}
 .animation-5 .shape4 {
	 animation: animation5shape4 2s ease 0s infinite reverse;
}
 @keyframes animation5shape4 {
	 0% {
		 transform: translate(0, 0);
	}
	 25% {
		 transform: translate(0, -15px);
	}
	 50% {
		 transform: translate(-15px, -15px);
	}
	 75% {
		 transform: translate(-15px, 0);
	}
}
 .animation-6 .shape1 {
	 animation: animation6shape1 2s linear 0s infinite normal;
}
 @keyframes animation6shape1 {
	 0% {
		 transform: translate(0, 0);
	}
	 25% {
		 transform: translate(0, 18px);
	}
	 50% {
		 transform: translate(18px, 18px);
	}
	 75% {
		 transform: translate(18px, 0);
	}
}
 .animation-6 .shape2 {
	 animation: animation6shape2 2s linear 0s infinite normal;
}
 @keyframes animation6shape2 {
	 0% {
		 transform: translate(0, 0);
	}
	 25% {
		 transform: translate(-18px, 0);
	}
	 50% {
		 transform: translate(-18px, 18px);
	}
	 75% {
		 transform: translate(0, 18px);
	}
}
 .animation-6 .shape3 {
	 animation: animation6shape3 2s linear 0s infinite normal;
}
 @keyframes animation6shape3 {
	 0% {
		 transform: translate(0, 0);
	}
	 25% {
		 transform: translate(18px, 0);
	}
	 50% {
		 transform: translate(18px, -18px);
	}
	 75% {
		 transform: translate(0, -18px);
	}
}
 .animation-6 .shape4 {
	 animation: animation6shape4 2s linear 0s infinite normal;
}
 @keyframes animation6shape4 {
	 0% {
		 transform: translate(0, 0);
	}
	 25% {
		 transform: translate(0, -18px);
	}
	 50% {
		 transform: translate(-18px, -18px);
	}
	 75% {
		 transform: translate(-18px, 0);
	}
}
 @media screen and (max-width: 600px) {
	 .content {
		 align-content: flex-start;
	}
	 .content .column {
		 width: calc(50% - 30px);
	}
}
 @media screen and (max-width: 400px) {
	 .content {
		 align-content: flex-start;
	}
	 .content .column {
		 width: calc(100% - 30px);
	}
}
 footer {
	 margin-top: 50px;
	 padding-bottom: 50px;
}
 footer p {
	 text-align: center;
	 margin: 0;
	 line-height: 20px;
	 font-size: 13px;
	 color: white;
	 font-weight: 400;
}
 footer p a {
	 color: #ffb74d;
}
 